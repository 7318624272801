import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { SyncSearchResult } from '@customTypes/shared/Sync';

export const fetchSyncsSearchRequest = createAction('fetchSyncsSearchRequest');
export const fetchSyncsSearchSuccess = createAction<SyncSearchResult[]>('fetchSyncsSearchSuccess');
export const fetchSyncsSearchFailure = createAction('fetchSyncsSearchFailure');

export const fetchSyncsSearch = (formData: SearchFormData) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    const { searchTerm, nodeTitle, sync, isSuccess, fromDate, toDate } = formData;
    dispatch(fetchSyncsSearchRequest());
    try {
        const { data }: Response = await api.get(
            `search?searchTerm=${searchTerm || ''}&nodeTitle=${nodeTitle || ''}&sync=${
                sync || ''
            }&isSuccess=${isSuccess}&fromDate=${fromDate ?? ''}&toDate=${toDate ?? ''}`,
        );
        dispatch(fetchSyncsSearchSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSyncsSearchFailure, e);
    }
};

interface Response {
    data: SyncSearchResult[];
}

export interface SearchFormData {
    searchTerm?: string | null;
    nodeTitle?: number | null;
    fromDate: string | null;
    toDate: string | null;
    isSuccess?: string; // boolean string
    sync?: number | null;
}
